import React, { useEffect } from "react";

import "./js/countdown.js";
import "./css/styles.scss";
import "./css/snowdrop.scss";
import 'bootstrap/dist/css/bootstrap.css';
import { snowdroponoff } from "./js/snowdroponoff.js";

export default function Home() {

    useEffect(() => {
        document.title = "Home | w/Yamroll";
    });

    const ClickRedirect = (where) => {
        switch (where) {
            case "Games":
                window.location.href = "/Games";
                break;
            case "Contact":
                window.location.href = "/contact";
                break;
            case "Tools":
                window.location.href = "/Tools";
                break;
            default:
                break;
        }
    }

    return (
        
        <div>
        <snowfall>
        <div id="snowdrop" style={{ "display":"none" }}>
        <snowflake><img src="https://raw.githubusercontent.com/LordEaster/ICON-LOGO/main/Carrot.png" />️️</snowflake>
        <snowflake><img src="https://raw.githubusercontent.com/LordEaster/ICON-LOGO/main/Carrot.png" />️️</snowflake>
        <snowflake><img src="https://raw.githubusercontent.com/LordEaster/ICON-LOGO/main/Carrot.png" />️️</snowflake>
        <snowflake><img src="https://raw.githubusercontent.com/LordEaster/ICON-LOGO/main/Carrot.png" />️️</snowflake>
        <snowflake><span>🥕</span></snowflake>
        <snowflake><span>🥕</span></snowflake>
        <snowflake><span>🥕</span></snowflake>
        <snowflake><span>🥕</span></snowflake>
        <snowflake><span>🌟</span></snowflake>
        <snowflake><span>🌟</span></snowflake>
        </div>
        <div class="all-element">
            <div class="top-element">
            <div style={{ "float":"left" }}>
            <a href="/clock"><button type="button" class="btn" style={{ "padding":"0", "margin":"0.5rem" }}>🕒</button></a>
            </div>
            <div style={{ "float":"right" }}>
            <label class="switch">
            <input type="checkbox" id="snowdropbutton" onClick={ snowdroponoff }></input>
            <span class="slider round"></span>
            </label>
            </div>
            </div>
            <div class="center-element" style={{ "height":"80vh" }}>
                <img src="https://raw.githubusercontent.com/LordEaster/ICON-LOGO/main/YamBanner.png" alt="YamYam" style={{ "display":"flex","placeContent":"center", "width":"60vw", "minWidth":"300px", "pointerEvents":"none" }} onContextMenu={(e) => {e.preventDefault()}} />
            <div>
                <button type="button" class="btn btn-outline-secondary" onClick={() => ClickRedirect('Games')}>🕹️ Just Games</button>

                <button type="button" class="btn btn-outline-secondary" onClick={() => ClickRedirect('Contact')}>🌐 Contact</button>

                <button type="button" class="btn btn-outline-secondary" onClick={() => ClickRedirect('Tools')}>🔧 Tools</button>
            </div>
            </div>
        </div>
        </snowfall>
        </div>
    );
}

