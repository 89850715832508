import React, { useEffect } from "react";

export default function GGG() {

    useEffect(() => {
        document.title = "Game Go Green | w/Yamroll";
    });

    return (
        <div>
            <iframe src="https://i.simmer.io/@LordEaster/ggg" style="width:1920px;height:1080px"></iframe>
        </div>
    );
}