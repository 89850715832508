import React, { useEffect } from "react";

import "../css/JOOD24VLT.scss"

export default function JOOD24VLT() {

    useEffect(() => {
        document.title = "Valentine 2024 with Jood | w/Yamroll";
    });

    function checkPassCode() {
        const hint = document.getElementById('hint')
        let passcode = document.getElementById('passcode').value;
        
        switch (passcode) {
            case "20JAN2023":
                console.log('Next Question: Lovingly, Our Valentine Evenings Journey On, Offering Devotion.')
                setTimeout(() => {
                    hint.innerText = "HINT!: SeE tHe FiRsT lEtTeR oF eAcH wOrD.";
                }, 10000);
                hint.innerText = "HINT!: Go to see next Pass Code on Console Inspector."
                break;
            case "LOVEJOOD":
                document.getElementById('content').style.display = "block"
                document.getElementById('hint').style.display = "none"
                document.getElementById('passcode').style.display = "none"

                break;
            default:
                break;
        }
    }
 
    return (
        <div className="smrt">
            <input className="form-control" id="passcode" type="text" placeholder="Enter the pass code." onChange={checkPassCode}/>
            <div id="hint"></div>
            <div id="content" style={{"display":"none"}}>
            <div id="card">
                <center><h1>Happy Valentines Day 2024, JOOD🩷</h1></center>
                <div className="heart" id="heart1">
                    <div id="half1">
                        <div id="circle"></div>
                        <div id="rec"></div>
                    </div>
                    <div id="half2">
                        <div id="circle"></div>
                        <div id="rec"></div>
                    </div>
                </div>
                <div id="message">
                    <h2>Thank you for no matter what I do, you're always by my side.</h2>
                </div>
                <div className="heart" id="heart2">
                    <div id="half1">
                        <div id="circle"></div>
                        <div id="rec"></div>
                    </div>
                    <div id="half2">
                        <div id="circle"></div>
                        <div id="rec"></div>
                    </div>
                </div>
            </div>
            </div>
        </div>
    )
}
