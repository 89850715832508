import React, { useEffect } from "react";

import "./css/styles.scss";
import "./css/ToolsPage.scss";

export default function ToolsPage() {

    useEffect(
        () => {
            document.title = 'Tools | w/Yamroll';
        });

    return (
        <div>
        <div class="center-element">
        <img src="https://raw.githubusercontent.com/LordEaster/ICON-LOGO/main/YamBanner.png" alt="YamYam" style={{ "display":"flex", "placeContent":"center", "width":"60vw", "minWidth":"384px" }} onContextMenu={(e) => {e.preventDefault()}} />
        <p class="fs-2">my Tools and my Toys</p>
        <div class="Itembox">

        <a href= "/Clock">
        <button type="button" class="btn btn-outline-secondary">🕒 Clock</button></a>

        <a href= "/Tools/TCAS65Calc" target="_blank">
        <button type="button" class="btn btn-outline-secondary">🧮 TCAS65 Calculator</button></a>

        <a href= "/Tools/SimpleCalc" target="_blank">
        <button type="button" class="btn btn-outline-secondary">🧮 Simple Calculator</button></a>

        <a href= "/Tools/Trends/SuffixTrendOnX" target="_blank">
        <button type="button" class="btn btn-outline-secondary">😘 Suffix Trend On X</button></a>

        <a href= "justmaths.withyamroll.com" target="_blank">
        <button type="button" class="btn btn-outline-secondary">🧮 JustMaths Tools</button></a>

        </div>
        </div>
        <div id="footer">
            <a href='/'>
            <button className='btn btn-outline-secondary'>Back to w/Yamroll</button>
            </a>
        </div>
        </div>
        
        
    );
}