import React, { useEffect } from 'react';
import './openmenu.css';

export default function Openmenu() {

    useEffect(() => {
        document.title = "L'amour Menu";
    });

    return (
      <div>
        <img src="https://raw.githubusercontent.com/LordEaster/BN/main/lamour-menu-1.png" alt="L'amour Menu" />
        <img src="https://raw.githubusercontent.com/LordEaster/BN/main/lamour-menu-2.png" alt="L'amour Menu" />
      </div>
    );
  }