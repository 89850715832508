const textArr = [
    "ค่ะ",
    "ค่า",
    "นะคะ",
    "งับ",
    "จ้า",
    "จ่ะ",
    "จัง",
    "จาง",
    "จุง",
    "ฮะ",
    "นะฮะ",
    "ครับ",
    "คับ",
    "คร้าบ",
    "คร้าฟ",
    "ค้าบ",
    "ครัช",
    "เด้อ",
    "เน้อ",
    "เวอร์",
    "จริง ๆ นะ",
    "อะ",
    "ง่ะ",
    "ง่า",
    "ง้าบ"
];

export function genSuffix() {
    let inputText = document.getElementById("input-text").value;

    let outputText = inputText + "\n";

    for (let index = 0; index < textArr.length; index++) {
        outputText += inputText + textArr[index] + "\n";
    }

    document.getElementById("output-text").innerText = outputText;
}

export function randSuffix() {
    const randomIndex = Math.floor(Math.random() * textArr.length);
    return textArr[randomIndex];
}

export function copyText() {
    let copyText = document.getElementById("output-text").innerText

    copyText.select();
    copyText.setSelectionRange(0, 99999);
  
    navigator.clipboard.writeText(copyText.value);
    setTimeout(() => {
        document.getElementById("copy-text").innerText = "Copied!"
    }, 2000)
    document.getElementById("copy-text").innerText = "Copy"
    alert("sasdasdasd")
}