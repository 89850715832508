export
const Discord = 'https://discord.gg/xtST2WSZKN';

export
const Donate = 'https://ko-fi.com/yamrolltherabbit';

export
const Ig = 'https://instagram.com/smart_np/';

export
const IgYam = 'https://instagram.com/yamroll.n.friends/';

export
const Twitter = 'https://twitter.com/withyamroll';

export
const GitHub = 'https://github.com/LordEaster';
