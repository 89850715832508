import React, { useEffect } from "react";

export default function Redi() {

    useEffect(() => {
        document.title = "Redirecting | w/Yamroll";
    });

    return (
        <div className="center-element">
            <h1>Redirecting</h1>
            <h3>Please wait a minute...</h3>
        </div>
    );
}