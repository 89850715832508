export function snowdroponoff() {
    // Get the checkbox
    var checkBox = document.getElementById("snowdropbutton");
    // Get the output text
    var text = document.getElementById("snowdrop");
  
    // If the checkbox is checked, display the output text
    if (checkBox.checked == true){
      text.style.display = "block";
    } else {
      text.style.display = "none";
    }
  }