import React, { useEffect } from "react";

// import "./BNstyles.scss";
import "../css/snowdrop.scss";
import 'bootstrap/dist/css/bootstrap.css';

export default function BNContact() {

    useEffect(() => {
        const faviconUpdate = async () => {
            const fav = document.getElementById("favicon");
            fav.href = "./BaannatICON.png";
        }
        faviconUpdate();
        
        document.title = "Contacts | Baannat Group";
    });

    return (
        
        <div>
            <div class="center-element">
                <img src="../baannat logo.png" alt="Baannat" class="IMG img-responsive mx-auto" style={{  "pointerEvents":"none" }} onContextMenu={(e) => {e.preventDefault()}} />
            <div>
                <p class="fs-2">Contacts | ติดต่องาน</p>

                <a href= '/Baannat/Contacts/Tel' target="_blank">
                <button type="button" class="btn btn-outline-secondary">📞 062-616-6595</button></a>

                <a href= '/Baannat/Contacts/FBPage' target="_blank">
                <button type="button" class="btn btn-outline-secondary"><img src="https://raw.githubusercontent.com/LordEaster/ICON-LOGO/main/Facebook_2022_icon.png" width="20" /> Facebook Page</button></a>

                <a href= '/Baannat/Contacts/LineOA' target="_blank">
                <button type="button" class="btn btn-outline-secondary"><img src="https://raw.githubusercontent.com/LordEaster/ICON-LOGO/main/lineoa_icon.png" width="20" /> line official</button></a>

                <a href= '/Baannat/Contacts/Email' target="_blank">
                <button type="button" class="btn btn-outline-secondary">✉️ Baannat@Hotmail.com</button></a>

            </div>
            </div>
        <div id="footer">
        <a href='/Baannat'>
        <button className='btn btn-outline-secondary'>Back to Home</button>
        </a>
        </div>
        </div>
    );
}

