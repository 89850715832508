import React, { useEffect } from "react";

import "./css/styles.scss";
import 'bootstrap/dist/css/bootstrap.css';

export default function JustGames() {

    useEffect(() => {
        document.title = "Just Games | w/Yamroll";
    });

    const ClickRedirect = (where) => {
        switch (where) {
            case 'A-Math':
                window.open('https://amath.withyamroll.com', '_blank');
                break;
            case 'Chess':
                window.open('https://chess.withyamroll.com', '_blank');
                break;
            case 'TicTacToe':
                window.location.href = '/Games/TicTacToe';
                break;
            case 'Minesweeper':
                window.location.href = '/Games/Minesweeper';
                break;
            case 'GGG':
                window.location.href = '/Games/GGG/releases';
                break;
            default:
                break;
        }
    }

    return (
    <div>
    <div class="center-element">
        <div style={{ "display":"grid", "placeContent":"center" }}>
        <img src="https://raw.githubusercontent.com/LordEaster/ICON-LOGO/main/YamBanner.png" alt="YamYam" style={{ "width":"30vw", "minWidth":"256px", "maxWidth":"512px", "pointerEvents":"none" }} onContextMenu={(e) => {e.preventDefault()}} />
        </div>
        <p class="fs-2">My Game Projects.</p>

        <div>
        <button type="button" class="btn btn-outline-secondary" onClick={() => ClickRedirect('A-Math')} disabled>➕ A-Math</button>

        <button type="button" class="btn btn-outline-secondary" onClick={() => ClickRedirect('Chess')} disabled>♘ Chess</button>

        <button type="button" class="btn btn-outline-secondary" onClick={() => ClickRedirect('TicTacToe')}>TicTacToe</button>

        <button type="button" class="btn btn-outline-secondary" onClick={() => ClickRedirect('Minesweeper')}>💣 Minesweeper</button>

        <button type="button" class="btn btn-outline-secondary" onClick={() => ClickRedirect('GGG')}>🌱 Game Go Green</button>
        </div>
    </div>
        <div id="footer">
        <a href='/'>
        <button className='btn btn-outline-secondary'>Back to w/Yamroll</button>
        </a>
        </div>
    </div>
    );
}

