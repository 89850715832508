import React, { useEffect, useState } from "react";
import "./TicTacToe.css";
import 'bootstrap/dist/css/bootstrap.css';

export default function TicTacToe() {

    useEffect(() => {
        document.title = "Tic Tac Toe | w/Yamroll";
    });

    const [board, setBoard] = useState(Array(9).fill(''));
    const [currentPlayer, setCurrentPlayer] = useState('X');
    const [winner, setWinner] = useState(null);

    const handleCellClick = (index) => {
        if (winner !== null) {
            resetGame();
        }

        if (board[index] === '' && winner === null) {
            const newBoard = [...board];
            newBoard[index] = currentPlayer;
            setBoard(newBoard);
            setCurrentPlayer(currentPlayer === 'X' ? 'O' : 'X');
            setWinner(checkWinner(newBoard));
        }
    };

    const checkWinner = (board) => {
        const winningPattern = [      
            [0, 1, 2],
            [3, 4, 5],
            [6, 7, 8],
            [0, 3, 6],
            [1, 4, 7],
            [2, 5, 8],
            [0, 4, 8],
            [2, 4, 6],
        ];

        for (let i = 0; i < winningPattern.length; i++) {
            const [a, b, c] = winningPattern[i];
            if (board[a] !== '' && board[a] === board[b] && board[b] === board[c]) {
                return board[a];
            }
        }

        if (board.indexOf('') === -1) {
            return 'tie';
        }

        return null;
    };

    const resetGame = () => {
        setBoard(Array(9).fill(''));
        setCurrentPlayer('X');
        setWinner(null);
    };

    const renderCell = (index) => {
        return (
            <div className="cell" onClick={() => handleCellClick(index)}>
                {board[index]}
            </div>
        );
    };

    return (
        <div className="game">
        <h1>Tic Tac Toe</h1>
        <div className="game-info">
            <div className="player">
            Current Player: {currentPlayer}
            </div>
        </div>
        <div className="game-board">
            {renderCell(0)}
            {renderCell(1)}
            {renderCell(2)}
            {renderCell(3)}
            {renderCell(4)}
            {renderCell(5)}
            {renderCell(6)}
            {renderCell(7)}
            {renderCell(8)}
        </div>
        {winner && (
            <div className="winner">
            {winner === 'tie' ? 'Tie' : `Player ${winner} wins`}
            </div>
        )}
        <button className="btn btn-secondary" onClick={resetGame}>Reset</button>
        <div id="footer">
            <a href='/'>
            <button className='btn btn-outline-secondary'>Back to w/Yamroll</button>
            </a>
        </div>
        </div>
    );
}