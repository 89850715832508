import React from 'react';
import { BrowserRouter as Router, Redirect, Route, Switch } from 'react-router-dom';
// import { Route, Switch } from "react-router";

import Home from './components/Home';
import Contact from './components/Contact';
import Redi from './components/redi';
import Time from './components/Time';
import ToolsPage from './components/ToolsPage';
import TCAS65Calc from './components/ToolsPage/TCAS65Calc';
import SimpleCalc from './components/ToolsPage/SimpleCalc';
import SuffixTrendOnX from './components/ToolsPage/suffixTrendOnX';

import BNHome from './components/Baannat/HomePage';
import BNContact from './components/Baannat/BNContact';
import BNRedi from './components/Baannat/Redi';
import Openmenu from './components/Baannat/openmenu';

import JustGames from './components/JustGames';
import GGG from './components/Games/GameGoGreen/GGG';
import GamePolicy from './components/Games/GameGoGreen/GamePolicy';
import TicTacToe from './components/Games/TicTacToe/TicTacToe';
import Minesweeper from './components/Games/Minesweeper/Game';

import JOOD24VLT from './components/Challenge/JOOD-24VLT';

function App() {
  
  return (    
    <Router>
      <Switch>
        <Route exact path="/"><Home /></Route>
        <Route exact path="/Contact"><Contact /></Route>
        <Route path="/Discord" component={() => { 
          window.location.href = "https://discord.gg/xtST2WSZKN";
          return <Redi />;
        }} />

        {/* TEMP */}
        <Route path="/temp/sakura" component={() => { 
          window.location.href = "https://drive.google.com/drive/folders/1fQjonlzGNr2zyFznlMWXsUKjhty8H594?usp=sharing";
          return <Redi />;
        }} />

        {/* JOOD */}
        <Route exact path="/JOOD/24VLT"><JOOD24VLT /></Route>


        {/* GGG Game Section */}
        <Route exact path="/Games/GGG/Policy"><GamePolicy /></Route>
        <Route path="/Games/GGG/releases" component={() => { 
          window.location.href = "https://github.com/LordEaster/GameGoGreen/releases";
          return <Redi />;
        }} />

        {/* Tools Section */}
        <Route exact path="/Clock"><Time /></Route>
        <Route exact path="/Tools"><ToolsPage /></Route>
        <Route exact path="/Tools/TCAS65Calc"><TCAS65Calc /></Route>
        <Route exact path="/Tools/SimpleCalc"><SimpleCalc /></Route>
        <Route exact path='/Tools/Trends/SuffixTrendOnX'><SuffixTrendOnX /></Route>


        <Route path="/Discords/wYam/WatchSCD" component={() => { 
          window.location.href = "https://calendar.google.com/calendar/embed?src=3148rjtm6spb0mnii8dv732un8%40group.calendar.google.com&ctz=Asia%2FBangkok";
          return <Redi />;
        }} />

        {/* Baannat Section */}
        <Route path="/BN/menu"><Openmenu /></Route>
        <Route exact path="/Baannat"><BNHome/></Route>
        <Route exact path="/Baannat/Contacts"><BNContact/></Route>
        <Route path="/Baannat/Forms/attendance" component={() => { 
          window.location.href = "https://forms.gle/f7k8BrgyBjXE1Cxm6";
          return <BNRedi />;
        }} />
        <Route path="/Baannat/Forms/satisf-assess" component={() => { 
          window.location.href = "https://forms.gle/QyVwKS769dBr1Z4K7";
          return <BNRedi />;
        }} />
        <Route path="/Baannat/Contacts/LineOA" component={() => { 
          window.location.href = "https://lin.ee/4LINfx";
          return <BNRedi />;
        }} />
        <Route path="/Baannat/Contacts/FBPage" component={() => { 
          window.location.href = "https://fb.com/Baannat.Organizer";
          return <BNRedi />;
        }} />
        <Route path="/Baannat/Contacts/Email" component={() => {
          window.location.href = "mailto:baannat@hotmail.com";
          return <BNRedi />;
        }} />
        <Route path="/Baannat/Contacts/Tel" component={() => {
          window.location.href = "tel:+6662-616-6595";
          return <BNRedi />;
        }} />
        
        {/* Just Games Section */}
        <Route exact path="/Games"><JustGames /></Route>
        <Route exact path="/Games/GGG/test"><GGG /></Route>
        <Route exact path="/Games/TicTacToe"><TicTacToe /></Route>
        <Route exact path="/Games/Minesweeper"><Minesweeper /></Route>

        {/* Redirect */}
        <Route path="*"><Redirect to = "/" /> </Route>
      </Switch>
    </Router>
  );
}

export default App;
