export function startTime() {
    const today = new Date();
    let h = today.getHours();
    let m = today.getMinutes();
    let s = today.getSeconds();
    h = checkTime(h);
    m = checkTime(m);
    s = checkTime(s);

    // get the first and second number of hours, minutes and seconds
    const h1 = String(h)[0];
    const h2 = String(h)[1];
    const m1 = String(m)[0];
    const m2 = String(m)[1];
    const s1 = String(s)[0];
    const s2 = String(s)[1];

    document.getElementById('clock-h1').innerHTML = h1;
    document.getElementById('clock-h2').innerHTML = h2;
    document.getElementById('clock-m1').innerHTML = m1;
    document.getElementById('clock-m2').innerHTML = m2;
    document.getElementById('clock-s1').innerHTML = s1;
    document.getElementById('clock-s2').innerHTML = s2;
    document.getElementById('clock-blink1').innerHTML = ":";
    document.getElementById('clock-blink2').innerHTML = ":";
    document.title = h +":"+ m +" | w/Yamroll";
    setTimeout(startTime, 1000);
  }
  
  function checkTime(i) {
    if (i < 10) {i = "0" + i};  // add zero in front of numbers < 10
    return i;
  }