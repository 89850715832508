import React, { useEffect } from "react";

import "../css/styles.scss"
import "../css/TCAS65Calc.scss";
import { genSuffix, randSuffix, copyText } from "../js/suffixTrendOnX";

export default function SuffixTrendOnX() {

    useEffect(
        () => {
            document.onload = randSuffix();
            document.onload = genSuffix();
        }
    );
 
    return (
        <div className="smrt">
            <div className="form" style={{"textAlign":"center"}}>
                แปะข้อความ
                <input type="text" id="input-text" defaultValue="" onChange={() => {genSuffix()}} className="ItemInput" style={{"width":"100%"}}></input>
            </div>

            <div className="summarypanel">
                <div id="output-text" className="ItemInput" style={{"width":"100%"}}></div>
            </div>
            <button id="copy-button" onClick={copyText}>Copy</button>
        </div>
    )
}