import React, { useEffect } from "react";

import "./css/Time.scss";
import { startTime } from "./js/Time";

export default function Time() {

    useEffect(
        () => {
            document.onload = startTime();
        });

    return (
        <div>
        <div class="center-element">
        <img src="https://raw.githubusercontent.com/LordEaster/ICON-LOGO/main/YamBanner.png" alt="YamYam" style={{ "display":"flex", "placeContent":"center", "width":"60vw", "minWidth":"384px" }} onContextMenu={(e) => {e.preventDefault()}} />
        <div id="clockUI" onContextMenu={(e) => {e.preventDefault()}}>
        <div id="clock" />
        <span id="clock-h1"></span>
        <span id="clock-h2"></span>
        <span id="clock-blink1"></span>
        <span id="clock-m1"></span>
        <span id="clock-m2"></span>
        <span id="clock-blink2"></span>
        <span id="clock-s1"></span>
        <span id="clock-s2"></span>
        </div>
        </div>
        <div id="footer">My Tools #1
        <a href='/'>
        <button className='btn btn-outline-secondary'>Back to w/Yamroll</button>
        </a>
        </div>
        </div>
        
    );
}
    
    
