import React, { useEffect } from "react";

import "../css/styles.scss"
import "../css/SimpleCalc.scss";
import 'bootstrap/dist/css/bootstrap.css';

import { display , clearScreen , solve } from "../js/SimpleCalc.js";

export default function SimpleCalc() {
    useEffect(() => {
        document.title = "Simple Calculator | wYam";
    });

    return (
        <div>
            <div style={{"display":"grid","placeContent":"center", "textAlign":"center"}}>
            <img src="https://raw.githubusercontent.com/LordEaster/ICON-LOGO/main/YamBanner.png" alt="YamYam" style={{ "display":"flex", "placeContent":"center", "width":"60vw", "minWidth":"384px" }} onContextMenu={(e) => {e.preventDefault()}} />
            </div>
            <div className="tet mx-auto">
            <div className="top-bar mx-auto">
                <input type="text" id="result" className="screen"/>
                <input type="button" value="C" onClick={
                    () => {
                        clearScreen();
                    }
                } className="clear"/>
            </div>
            <div className="keys">
                <input type="button" value="7" className="button" onClick={() => {
                    display('7');
                }}/>
                <input type="button" value="8" className="button" onClick={() => {
                    display('8');
                }}/>
                <input type="button" value="9" className="button" onClick={() => {
                    display('9');
                }}/>
                <input type="button" value="/" className="operator" onClick={() => {
                    display('/');
                }}/>
                <input type="button" value="4" className="button" onClick={() => {
                    display('4');
                }}/>
                <input type="button" value="5" className="button" onClick={() => {
                    display('5');
                }}/>
                <input type="button" value="6" className="button" onClick={() => {
                    display('6');
                }}/>
                <input type="button" value="*" className="operator" onClick={() => {
                    display('*');
                }}/>
                <input type="button" value="1" className="button" onClick={() => {
                    display('1');
                }}/>
                <input type="button" value="2" className="button" onClick={() => {
                    display('2');
                }}/>
                <input type="button" value="3" className="button" onClick={() => {
                    display('3');
                }}/>
                <input type="button" value="-" className="operator" onClick={() => {
                    display('-');
                }}/>
                <input type="button" value="0" className="button" onClick={() => {
                    display('0');
                }}/>
                <input type="button" value="." className="button" onClick={() => {
                    display('.');
                }}/>
                <input type="button" value="=" className="button equal" onClick={() => {
                    solve();
                }}/>
                <input type="button" value="+" className="operator" onClick={() => {
                    display('+');
                }}/>
            </div>
            </div>
            <div id="footer">My Tools #3
            <a href='/'>
            <button className='btn btn-outline-secondary'>Back to w/Yamroll</button>
            </a>
            </div>
        </div>
    );
}
