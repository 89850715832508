import React, { useEffect } from "react";

// import "./BNstyles.scss";
import "../css/snowdrop.scss";
import 'bootstrap/dist/css/bootstrap.css';
import { snowdroponoff } from "../js/snowdroponoff.js";

export default function BNHome() {

    useEffect(() => {
        const faviconUpdate = async () => {
            const fav = document.getElementById("favicon");
            fav.href = "./BaannatICON.png";
        }
        faviconUpdate();
        
        document.title = "Home | Baannat Group";
    });

    return (
        
        <div>
        <snowfall>
        <div id="snowdrop" style={{ "display":"none" }}>
        <snowflake><span>❄️</span></snowflake>
        <snowflake><span>❄️</span></snowflake>
        <snowflake><span>🌸</span></snowflake>
        <snowflake><span>🌸</span></snowflake>
        <snowflake><span>🌸</span></snowflake>
        <snowflake><span>🌺</span></snowflake>
        <snowflake><span>💮</span></snowflake>
        <snowflake><span>🌷</span></snowflake>
        <snowflake><span>🌹</span></snowflake>
        </div>
        <div class="all-element">
            <div class="top-element">
            <div style={{ "float":"right" }}>
            <label class="switch">
            <input type="checkbox" id="snowdropbutton" onClick={ snowdroponoff }></input>
            <span class="slider round"></span>
            </label>
            </div>
            </div>
            <div class="center-element" style={{ "height":"90vh" }}>
                <img src="./baannat logo.png" alt="Baannat" class="IMG img-responsive mx-auto" style={{  "pointerEvents":"none" }} onContextMenu={(e) => {e.preventDefault()}} />
            <div>
                <a href="/Baannat/Forms/attendance">
                <button type="button" class="btn btn-outline-secondary">📝 ลงทะเบียนเข้างาน</button></a>

                <a href="/Baannat/Forms/satisf-assess">
                <button type="button" class="btn btn-outline-secondary">📝 กรอกแบบประเมิน</button></a>

                <a href="Baannat/Contacts">
                <button type="button" class="btn btn-outline-secondary">🌐 Contacts | ติดต่องาน</button></a>
            </div>
            </div>
        </div>
        </snowfall>
        </div>
    );
}

