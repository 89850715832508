import React, { useEffect } from "react";

import "./css/styles.scss";
import 'bootstrap/dist/css/bootstrap.css';
import { Discord, Ig, IgYam, Twitter, GitHub, DisLOK } from "./Linkset.js";

export default function Home() {

    useEffect(() => {
        document.title = "Contact | w/Yamroll";
    });

    const clickRedirect = (where) => {
        switch (where) {
            case 'Ig':
                window.open(Ig);
                break;
            case 'Discord':
                window.open(Discord);
                break;
            case 'IgYam':
                window.open(IgYam);
                break;
            case 'Twitter':
                window.open(Twitter);
                break;
            case 'GitHub':
                window.open(GitHub);
                break;
            case 'Email':
                window.location.href = `mailto:smary1172@gmail.com`
            default:
                break;

        }
    }

    return (
    <div>
        <div class="center-element">
        <div style={{ "display":"grid", "placeContent":"center" }}>
        <img src="https://raw.githubusercontent.com/LordEaster/ICON-LOGO/main/YamBanner.png" class="IMG img-responsive mx-auto" alt="YamYam" style={{ "width":"30vw", "minWidth":"256px", "maxWidth":"512px", "pointerEvents":"none" }} onContextMenu={(e) => {e.preventDefault()}} />
        </div>
        <p class="fs-2">My Contact and Social media</p>
        <div class="Itembox">
        <button type="button" class="btn btn-outline-secondary"onClick={() => clickRedirect('Ig')} ><img src="https://raw.githubusercontent.com/LordEaster/ICON-LOGO/bc7716e4325805397b5def7cb9d373b8fb43acd0/Instagram_icon.svg" width="20"/> smart_np</button>

        <button type="button" class="btn btn-outline-secondary" onClick={() => clickRedirect('IgYam')}><img src="https://raw.githubusercontent.com/LordEaster/ICON-LOGO/bc7716e4325805397b5def7cb9d373b8fb43acd0/Instagram_icon.svg" width="20" /> yamroll.n.friends</button>

        <button type="button" class="btn btn-outline-secondary" onClick={() => clickRedirect('Twitter')}><img src="https://raw.githubusercontent.com/LordEaster/ICON-LOGO/1ff0ec2ed082f82bd95fed471f3ed707d072d1da/Twitter_bird_icon.svg" width="20" /> Twitter</button>

        <button type="button" class="btn btn-outline-secondary" onClick={() => clickRedirect('GitHub')}><img src="https://raw.githubusercontent.com/LordEaster/ICON-LOGO/1ff0ec2ed082f82bd95fed471f3ed707d072d1da/Github_icon.svg" width="20" /> GitHub</button>

        <button type="button" class="btn btn-outline-secondary" onClick={() => clickRedirect('Discord')}><img src="https://raw.githubusercontent.com/LordEaster/ICON-LOGO/1ff0ec2ed082f82bd95fed471f3ed707d072d1da/Discord_icon.svg" width="20" />  w/Yamroll</button>

        <button type="button" class="btn btn-outline-secondary" onClick={() => clickRedirect('Email')}>✉️ Email</button>
        </div>
        </div>
    <div id="footer">
        <a href='/'>
        <button className='btn btn-outline-secondary'>Back to w/Yamroll</button>
        </a>
    </div>
    </div>
    );
}

